import React from "react";
import { Navbar } from "@nextui-org/react";
import { Link } from "react-router-dom";


function HeaderBar() {
  return (
    <Navbar isBordered variant="sticky">
      <Navbar.Brand>
        <h3>JD</h3>
      </Navbar.Brand>
      <Navbar.Content >
        <Link to="/">Home</Link>
        <Link to="/projects">Projects</Link>
        <Link to="/blog">Blogs</Link>
        <Link to="/whyme">Why Me?</Link>
      </Navbar.Content>
    </Navbar>
  )
};

export default HeaderBar;
