import React, { Component } from "react";
import { Text } from "@nextui-org/react";
import './AIProjects.css'

class AIProjects extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (project) {
      return (
        <ul>
          <li>
            <Text h5 color="success">{project.name} - {project.start_date}</Text>
          </li>
          <li>
            <Text h7><b>Project overview:</b> {project.scale} </Text>
          </li>
          <li>
            <Text h7><b>Impact:</b> {project.impact}</Text>
          </li>
          <li>
            <Text h7><b>Description:</b> {project.description}</Text>
          </li>
          <li>
            <Text h7><b>Technology:</b> {project.technology}</Text>
          </li>
          <li>
            <Text h7><b>My roles:</b> </Text>
              {project.roles}
          </li>

        </ul>
      )
    });
    return (
      <div className="AIProjects">
        {projects}
      </div>
    );
  }
}

export default AIProjects;