import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
// import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';

const sections = [
  { title: 'Data', url: '#' },
  { title: 'AI', url: '#' },
  { title: 'MLOps', url: '#' },
  { title: 'Leadership', url: '#' },
  { title: 'Style', url: '#' },
];

const mainFeaturedPost = {
  title: 'Hello there!',
  description:
    "Whether you are a hiring manager looking for a potential candidate or an inspired entry-level starter who is finding a way into data science, I hope througout my page, you would have a glimpse into my journey and something useful for your journey.",
  image: 'https://source.unsplash.com/random',
  imageText: 'main image description',
};

// const featuredPosts = [
//   {
//     title: 'Featured post',
//     date: 'Nov 12',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random',
//     imageLabel: 'Image Text',
//   },
//   {
//     title: 'Post title',
//     date: 'Nov 11',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random',
//     imageLabel: 'Image Text',
//   },
// ];

// const posts = [post1, post2, post3];

const sidebar = {
  title: 'About',
  description:
    'I enjoy seeing the flow of the business throught the lens of data.',
  milestones: [
    { title: 'Jun 2008', content: "I received a full-time scholarship to KAIST (1st ranked in Korea in tech)" },
    { title: 'Jun 2012', content: "I graduated with a Cum Laude award." },
    { title: 'Sep 2012', content: "I received VEF - a scholarship worth of 52,000 USD - to pursue my PhD at Michigan State University, USA." },
    { title: 'Jan 2015', content: "I married to a wonderful woman." },
    { title: 'Jan 2016', content: "I welcomed my 1st little princess." },
    { title: 'Mar 2017', content: "I accepted an offer and moved to Australia." },
    { title: 'Oct 2019', content: "I welcomed my 2nd little princess." },
    { title: 'Oct 2022', content: "I officially became a citizen of Australia." },
  ],
  social: [
    { name: 'My Github', icon: GitHubIcon , url: "https://github.com/dohuan"},
    { name: 'My Gitlab', icon: GitHubIcon , url: "https://gitlab.com/huando"}
  ],
};

const theme = createTheme();

export default function Blog() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="Blog" sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          {/* <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid> */}
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="Articles" />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              milestones={sidebar.milestones}
              social={sidebar.social}
            />
          </Grid>
        </main>
      </Container>
      <Footer
        title="Thank You!"
        description="My deep appreciation for your time."
      />
    </ThemeProvider>
  );
}
