import { Card, Col, Text } from "@nextui-org/react";

export default function CardTemplate(props) {
  return (
    <Card>
      <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
        <Col>
          <Text size={12} weight="bold" transform="uppercase" color={props.textColor} >
            {props.header}
          </Text>
          <Text h4 color={props.textColor}>
            {props.body}
          </Text>
        </Col>
      </Card.Header>
      <Card.Image
        // src={props.img}
        src={"/images/card_skills_cloud_service1.jpg"}
        objectFit="cover"
        width="100%"
        height={props.height} // 340
        alt="Card image background"
      />
    </Card>
  )
};
