import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';
import post0 from './Articles/post0.md'
import post1 from './Articles/post1.md'
import post2 from './Articles/post2.md'
import post3 from './Articles/post3.md'
import post4 from './Articles/post4.md'
import post5 from './Articles/post5.md'
import { Divider } from '@mui/material';

class Entry extends Component {
  constructor(props) {
    super(props)

    this.state = {
      post0: "",
      post1: "",
      post2: "",
      post3: "",
      post4: "",
      post5: "",
    }
  }

  componentWillMount() {
    fetch(post0)
      .then((res) => res.text())
      .then((post0) => {
        this.setState({ post0 })
      })
    fetch(post1)
      .then((res) => res.text())
      .then((post1) => {
        this.setState({ post1 })
      })
    fetch(post2)
      .then((res) => res.text())
      .then((post2) => {
        this.setState({ post2 })
      })
    fetch(post3)
      .then((res) => res.text())
      .then((post3) => {
        this.setState({ post3 })
      })
    fetch(post4)
      .then((res) => res.text())
      .then((post4) => {
        this.setState({ post4 })
      })
    fetch(post5)
      .then((res) => res.text())
      .then((post5) => {
        this.setState({ post5 })
      })
  }

  render() {

    let { post0 } = this.state
    let { post1 } = this.state
    let { post2 } = this.state
    let { post3 } = this.state
    let { post4 } = this.state
    let { post5 } = this.state

    return (
      <div className="post">
        <img src="https://storage.googleapis.com/jaydo-homepage-public/card_skills_programming.jpg" alt='Some cool view' />
        <Markdown children={post0} />
        <Divider>END</Divider>
        <br />

        <img src="https://storage.googleapis.com/jaydo-homepage-public/blog/mechanical_diagram_measure.jpg" alt='Some cool view' />
        <Markdown children={post1} />
        <Divider>END</Divider>
        <br />

        <img src="https://source.unsplash.com/random" alt='Some cool view' />
        <Markdown children={post2} />
        <Divider>END</Divider>
        <br />

        <img src="https://storage.googleapis.com/jaydo-homepage-public/blog/example_raw_image.png" alt='Some cool view' /><img src="https://storage.googleapis.com/jaydo-homepage-public/blog/example_filtered_image.png" alt='Some cool view' />
        <Markdown children={post3} />
        <Divider>END</Divider>
        <br />

        <img src="https://source.unsplash.com/random" alt='Some cool view' />
        <Markdown children={post4} />
        <Divider>END</Divider>
        <br />

        <img src="https://source.unsplash.com/random" alt='Some cool view' />
        <Markdown children={post5} />
        <Divider>END</Divider>
        <br />
      </div>
    )
  }
}

export default Entry;