import React, { Component } from "react";
import Flip from "react-reveal/Flip";
import { Text, Grid } from "@nextui-org/react";
import CardTemplate from "./SkillCards/CardTemplate";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <Text
            h1
            size={15}
            css={{
              textGradient: "45deg, $yellow600 -20%, $red600 100%",
            }}
            weight="bold"
          >
            <h3>{education.school}</h3>
          </Text>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });

    const work = this.props.data.work.map(function (work) {
      return (
        <div key={work.company}>
          <Text
            h1
            size={15}
            css={{
              textGradient: "45deg, $yellow600 -20%, $red600 100%",
            }}
            weight="bold"
          >
            <h3>{work.company}</h3>
          </Text>
          <p className="info">
            <b>{work.title}</b>
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <p>{work.description}</p>
          <p>{work.where}</p>
        </div>
      );
    });

    const skills = this.props.data.skills;

    return (
      <section id="resume">
        <Flip left>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Education</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{education}</div>
              </div>
            </div>
          </div>
        </Flip>

        <Flip left>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Work</span>
              </h1>
            </div>

            <div className="nine columns main-col">{work}</div>
          </div>
        </Flip>

        <Flip left>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>
            <div>
              <Grid.Container gap={2} justify="center">
                <Grid xs={12} sm={6}>
                  <CardTemplate header="OS" body={skills.OS.list} img={skills.OS.cardimg} textColor={skills.OS.textcolor} height={skills.OS.height} />
                  <CardTemplate header="Cloud Services" body={skills.CloudServices.list} img={skills.CloudServices.cardimg} textColor={skills.CloudServices.textcolor} height={skills.CloudServices.height} />
                  <CardTemplate header="Programming" body={skills.programming.list} img={skills.programming.cardimg} textColor={skills.programming.textcolor} height={skills.programming.height} />
                </Grid>
              </Grid.Container>
              <Grid.Container gap={1} justify="center">
                <Grid xs={12} sm={3}>
                  <CardTemplate header="ML Ops" body={skills.mlops.list} img={skills.mlops.cardimg} textColor={skills.mlops.textcolor} height={skills.mlops.height} />
                </Grid>
                <Grid xs={12} sm={3}>
                  <CardTemplate header="ML Tools" body={skills.mltools.list} img={skills.mltools.cardimg} textColor={skills.mltools.textcolor} height={skills.mltools.height} />
                </Grid>
              </Grid.Container>
              <Grid.Container gap={1} justify="center">
                <Grid xs={12} sm={3}>
                  <CardTemplate header="Database" body={skills.database.list} img={skills.database.cardimg} textColor={skills.database.textcolor} height={skills.database.height} />
                </Grid>
                <Grid xs={12} sm={3}>
                  <CardTemplate header="Visualization" body={skills.viz.list} img={skills.viz.cardimg} textColor={skills.viz.textcolor} height={skills.viz.height} />
                </Grid>
              </Grid.Container>
              <Grid.Container gap={1} justify="center">
                <Grid xs={12} sm={6}>
                  <CardTemplate header="AI Technologies" body={skills.aitech.list} img={skills.aitech.cardimg} textColor={skills.aitech.textcolor} height={skills.aitech.height} />
                </Grid>
              </Grid.Container>
            </div>
          </div>
        </Flip>
      </section>
    );
  }
}

export default Resume;
