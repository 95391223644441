import React, { Component } from 'react';
import Home from "./Components/Home";
import WhyMe from "./Components/WhyMe";
import Blog from "./Components/Blog/Blog"
import AIProjects from './Components/AIProjects';
import HeaderBar from "./Components/HeaderBar";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import $ from "jquery";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {}
    };
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    return (
      <Router>
        <div style={{ alignItems: "center" }}>
          <HeaderBar />
          <Routes>
            <Route path="/whyme" element={<WhyMe data={this.state.resumeData} />} />
            <Route path="/blog" element={<Blog data={this.state.resumeData} />} />
            <Route path="/projects" element={<AIProjects data={this.state.resumeData} />} />
            <Route path="/" element={<Home data={this.state.resumeData} />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;

