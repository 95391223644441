import React, { Component } from 'react';
import Header from './Header';
import About from './About';
import Resume from './Resume';
import Footer from './Footer';
import './Home.css'


class Home extends Component {
  render() {
    if (!this.props.data) return null;
    return (
      <div className='Home'>
        <Header data={this.props.data.main} />
        <About data={this.props.data.main} />
        <Resume data={this.props.data.resume} />
        <Footer data={this.props.data.main} />
      </div>
    );
  }
}

export default Home;
