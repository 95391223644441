import React, { Component } from "react";
import Fade from "react-reveal";
import { Grid, Card, Text, Tooltip } from '@nextui-org/react';

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = "images/" + this.props.data.image;
    // const name = this.props.data.name;
    const bio = this.props.data.bio;

    return (
      <header id="home">
        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <Grid.Container direction="column" alignItems="center">
                <Grid>
                  <Tooltip content={"My name is Jay and welcome to my page!"} rounded color="primary">
                    <Text
                      h1
                      size={60}
                      css={{
                        textGradient: "45deg, $yellow600 -20%, $red600 100%",
                      }}
                      weight="bold"
                    >
                      Hello there!
                    </Text>
                  </Tooltip>
                </Grid>
              </Grid.Container>
            </Fade>
            <Fade bottom duration={1200}>
              <Grid.Container gap={2} direction="column" alignItems="center">
                <Grid>
                  <img
                    className="profile-pic"
                    src={profilepic}
                    alt="Jay (Huan) Do"
                    width="150"
                    height="170"
                  />
                  <Text>
                    Jay (Huan) Do, Ph.D.
                  </Text>
                </Grid>
                <Grid>
                  <Card css={{ mw: "1000px" }}>
                    <Card.Body>
                      <Text h6>
                        {bio}
                      </Text>
                    </Card.Body>
                  </Card>
                </Grid>
              </Grid.Container>
            </Fade>
            <hr />
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
