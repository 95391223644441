import React, { Component } from "react";
import Fade from "react-reveal";
import { Card } from "@nextui-org/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const longintro = this.props.data.longintro;
    const email = this.props.data.email;
    const socials = this.props.data.social;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="nine columns main-col">
              <h2>About Me</h2>
              <div
                className="row about-me"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card css={{ mw: "1000px" }}>
                  <Card.Body>
                    <p>{longintro}</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="row - contact">
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <CopyToClipboard
                    text={email}
                    onCopy={() => this.setState({ copied: true })}
                  >
                    <button>Copy my email</button>
                  </CopyToClipboard>
                  {socials.map((p) => (
                    <span>
                      <a href={p.url}>
                        <button type="button" className="btn btn-info">
                          {p.name}
                        </button>
                      </a>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
