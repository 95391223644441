import React, { Component } from "react";
import { Collapse, Text } from "@nextui-org/react";
import './WhyMe.css'

class WhyMe extends Component {
  render() {
    if (!this.props.data) return null;

    const reasons = this.props.data.whyme.context.map(function (reason) {
      return (
        <Collapse title={reason.title}>
          <Text>
            {reason.content}
          </Text>
        </Collapse>
      )
    });
    return (
      <div className="WhyMe">
        Why would you want me in your team?
        <br />
        Because I am ...
        <Collapse.Group>
          <div className="nine columns main-col">
            {reasons}
          </div>
        </Collapse.Group>
      </div>
    );
  }
}

export default WhyMe;